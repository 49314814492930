import { Navigate, Route, useLocation } from 'react-router'
import React from 'react'

import Routes from '../utils/SentryRoutes'
import Loading from '../components/Loading'
import Onboarding from '../onboarding'

import { RedirectToSignIn, useAuth } from '@olino/auth-client/react'

const RedirectQuotation = () => {
  const location = useLocation()

  const url = `/onboarding${location.pathname.split('/quotation')[1]}`

  return <Navigate to={url} />
}

const Auth = React.lazy(() => import('./auth'))
const Cockpit = React.lazy(() => import('./cockpit'))
const Partner = React.lazy(() => import('./Partner'))

const MainRouter = () => {
  const { isLoggedIn } = useAuth()

  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        <Route path="auth/*" element={<Auth />} />

        <Route path="partner" element={<Partner />} />

        <Route path="onboarding/*" element={<Onboarding />} />

        <Route path="quotation/*" element={<RedirectQuotation />} />

        <Route index path="*" element={isLoggedIn ? <Cockpit /> : <RedirectToSignIn />} />
      </Routes>
    </React.Suspense>
  )
}

export default MainRouter
