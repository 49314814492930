import { type IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { useEffect } from 'react'
import { useParams } from 'react-router'

import { type OnboardingPage } from '../routes'
import { PRODUCTS } from '../../constants/products'
import { useQuotation } from '../context'
import { ProductEnum } from '../../generated/graphql'
import { track } from '../../utils/analytics'
import usePartner from '../../utils/usePartner'
import { ProductCard } from '../components/ProductCard'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { useOnboardingWorkflow } from '../workflow'
import { TipsCard } from '../components/TipsCard'

import { Button, Loader, Tooltip } from '@olino/design-system'

const categories: Array<{
  name: string
  as: 'h2' | 'h3'
  description?: string
  products: Array<{ title: string; description: string; icon: IconDefinition; value: ProductEnum }>
}> = [
  {
    name: 'Olino Autonomie',
    as: 'h2',
    description: 'Choisissez les produits qui correspondent à votre activité.',
    products: [
      {
        title: 'Responsabilité Civile Professionnelle',
        description:
          'La responsabilité civile professionnelle couvre les dommages causés à des tiers par votre activité.',
        icon: PRODUCTS[ProductEnum.PublicLiability].icon,
        value: ProductEnum.PublicLiability,
      },
      {
        title: 'Multirisque',
        description:
          "L'assurance multirisque couvre les dommages causés à votre matériel et à vos locaux.",
        icon: PRODUCTS[ProductEnum.MultiRisk].icon,
        value: ProductEnum.MultiRisk,
      },
    ],
  },
  // {
  //   name: 'Olino Plus+',
  //   as: 'h2',
  //   description: "La solution d'assurance pour vos besoins personalisés.",
  //   products: [
  //     {
  //       title: 'Protection juridique',
  //       description: 'Evitez les litiges juridiques et les frais qui y sont associés.',
  //       icon: PRODUCTS[ProductEnum.Legal].icon,
  //       value: ProductEnum.Legal,
  //     },
  //     {
  //       title: 'RC Décennale',
  //       description:
  //         'Professionnels du bâtiment et de la construction, couvrir vos chantiers est obligatoire.',
  //       icon: PRODUCTS[ProductEnum.Decennial].icon,
  //       value: ProductEnum.Decennial,
  //     },
  //   ],
  // },
  // {
  //   name: "Protection de l'activité",
  //   as: 'h3',
  //   products: [
  //     {
  //       title: 'Cybersécurité',
  //       description:
  //         'Protégez vos données, votre e-réputation et vos systèmes informatiques des attaques.',
  //       icon: PRODUCTS[ProductEnum.Cyber].icon,
  //       value: ProductEnum.Cyber,
  //     },
  //   ],
  // },
  // {
  //   name: 'Protection des personnes',
  //   as: 'h3',
  //   products: [
  //     {
  //       title: 'Prévoyance',
  //       description: "Protégez vos employés en cas d'accident, de maladie ou de décès.",
  //       icon: PRODUCTS[ProductEnum.Life].icon,
  //       value: ProductEnum.Life,
  //     },
  //     {
  //       title: 'Mutuelle',
  //       description: 'Couvre la santé de vos équipes.',
  //       icon: PRODUCTS[ProductEnum.Health].icon,
  //       value: ProductEnum.Health,
  //     },
  //     {
  //       title: 'RC des mandataires sociaux',
  //       description:
  //         "Protège l'ensemble des dirigeants et mandataires sociaux ainsi que leur patrimoine personnel lorsque leur responsabilité est engagée.",
  //       icon: PRODUCTS[ProductEnum.DAndO].icon,
  //       value: ProductEnum.DAndO,
  //     },
  //     {
  //       title: 'Homme Clé',
  //       description:
  //         "Compensez les pertes et les préjudices liés à la perte d'une personne essentielle à votre activité.",
  //       icon: PRODUCTS[ProductEnum.KeyPerson].icon,
  //       value: ProductEnum.KeyPerson,
  //     },
  //   ],
  // },
  // {
  //   name: 'Protection des véhicules',
  //   as: 'h3',
  //   products: [
  //     {
  //       title: 'Auto',
  //       description: "L'assurance auto covure les dommages causés à votre véhicule et à des tiers.",
  //       icon: PRODUCTS[ProductEnum.Auto].icon,
  //       value: ProductEnum.Auto,
  //     },
  //     {
  //       title: 'Auto mission',
  //       description:
  //         "L'assurance auto mission couvre les dommages causés au véhicule de vos employés lors de leurs déplacements professionnels.",
  //       icon: PRODUCTS[ProductEnum.AutoMission].icon,
  //       value: ProductEnum.AutoMission,
  //     },
  //   ],
  // },
]

const Main = () => {
  const { id } = useParams()
  const { updateQuotation } = useQuotation()
  const { partner, code: partnerCode } = usePartner()
  const { proceed } = useOnboardingWorkflow()

  const handleSelect = (product: ProductEnum) => {
    track({
      event: 'submitted_product',
      quotation_id: id,
    })

    proceed(() =>
      updateQuotation({
        details: {
          products: [product],
        },
      })
    )
  }

  useEffect(() => {
    track({
      event: 'visited_product',
      quotation_id: id,
    })
  }, [id])

  if (partnerCode !== null && partner === null) return <Loader />

  return (
    <>
      <OnboardingTitle.H1>Quel est votre besoin&nbsp;?</OnboardingTitle.H1>

      <section className="space-y-8">
        {categories.map((category) => {
          const productList = partner
            ? category.products.filter(
                (product) =>
                  partner?.products &&
                  partner.products.some(
                    (partnerProduct) =>
                      partnerProduct.product === product.value &&
                      partnerProduct.displayDuringQuotation
                  )
              )
            : category.products

          const isCategoryEmpty = partner && productList.length === 0

          if (isCategoryEmpty) return null

          return (
            <div key={category.name}>
              {/* {!isCategoryEmpty && category.as === 'h2' ? (
                <OnboardingTitle.H2Products>{category.name}</OnboardingTitle.H2Products>
              ) : (
                <OnboardingTitle.H3Products>{category.name}</OnboardingTitle.H3Products>
              )} */}
              {/* {category.description && <p className="mb-3">{category.description}</p>} */}
              <div className="grid gap-3 sm:grid-cols-2">
                {productList.map((product) => (
                  <ProductCard
                    key={product.value}
                    onClick={() => handleSelect(product.value)}
                    name={product.title}
                    icon={product.icon}
                    description={product.description}
                  />
                ))}
              </div>
            </div>
          )
        })}
      </section>

      <div className="mt-7 text-center">
        <span>Vous avez un autre besoin ? </span>

        <Button variant="link" id="cant-find-product">
          Cliquez ici pour en discuter avec un de nos experts.
        </Button>

        <Tooltip
          content="Veuillez nous faire part de votre demande par email à notre service commercial à gestion@olino.fr"
          anchorSelect="#cant-find-product"
        />
      </div>
    </>
  )
}

const Tips = () => {
  return (
    <div className="space-y-3">
      <TipsCard
        title="Notre parcours en ligne"
        content={[
          "Nous vous proposons une expérience en ligne pour souscrire à vos produits d'assurance. Vous pouvez à tout moment contacter un de nos experts pour poser vos questions.",
          'Si nous ne sommes pas en mesure de vous fournir un prix immédiatement, un de nos experts reviendra vers vous en moins de 48h.',
        ]}
      />
    </div>
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage
