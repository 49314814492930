import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { isBefore } from 'date-fns'
import { useEffect } from 'react'
import { useParams } from 'react-router'

import { type OnboardingPage } from '../routes'
import useDebounce from '../../utils/useDebounce'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { useQuotation } from '../context'
import { useOnboardingWorkflow } from '../workflow'
import { TipsCard } from '../components/TipsCard'
import { OnboardingButtons } from '../components/OnboardingButtons'
import { track } from '../../utils/analytics'

import { CountryEnum, replaceNullsWithUndefineds } from '@olino/utils'
import { FormError, Input, Button, Tooltip } from '@olino/design-system'

// This is a duplicate of the WelcomePage component in src/pages/onboarding/00-Welcome/Welcome.tsx. The goal is to show how to use the new layout in the onboarding flow.
// When the new layout is ready, we will solely use this WelcomePage and remove the one in src/pages/onboarding/00-Welcome/Welcome.tsx.

z.date()

const schema = z.object({
  name: z
    .string({ required_error: 'Le champ est requis' })
    .trim()
    .min(1, { message: "Le nom de l'entreprise est obligatoire" }),
  siret: z
    .string({ required_error: 'Le champ est requis' })
    .trim()
    .regex(/^\d{14}$/g, { message: "Le numéro de SIRET n'est pas valide" }),
  nafCode: z
    .string({ required_error: 'Le champ est requis' })
    .trim()
    .regex(/^\d\d\.\d\d[A-Z]$/g, { message: 'Le code NAF doit être au format 12.34A' }),
  legalStatus: z
    .string({ required_error: 'Le champ est requis' })
    .trim()
    .min(1, { message: 'Le statut juridique est obligatoire' }),
  foundedAt: z
    .string({ required_error: 'Le champ est requis' })
    .refine((value) => isBefore(new Date(value), new Date()), {
      message: 'La date de création doit être antérieure à aujourd’hui',
    }),
  address: z.object({
    line1: z
      .string({ required_error: 'Le champ est requis' })
      .trim()
      .min(1, { message: "L'adresse est obligatoire" }),
    line2: z.string({ required_error: 'Le champ est requis' }).trim().nullable(),
    city: z
      .string({ required_error: 'Le champ est requis' })
      .trim()
      .min(1, { message: 'La ville est obligatoire' }),
    postalCode: z
      .string({ required_error: 'Le champ est requis' })
      .trim()
      .regex(/^\d{5}$/, { message: 'Le code postal est invalide' }),
  }),
})

type FormInputs = z.infer<typeof schema>

const Main = () => {
  const { quotation, updateQuotation } = useQuotation()
  const { proceed } = useOnboardingWorkflow()
  const { id } = useParams()

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm<FormInputs>({
    mode: 'onSubmit',
    resolver: zodResolver(schema),
    defaultValues: {
      name: quotation?.details?.company?.name ?? '',
      siret: quotation?.details?.company?.siret ?? '',
      nafCode: quotation?.details?.company?.nafCode ?? '',
      legalStatus: quotation?.details?.company?.legalStatus ?? '',
      foundedAt: quotation?.details?.company?.foundedAt ?? '',
      address: {
        line1: quotation?.details?.company?.address?.line1 ?? '',
        line2: quotation?.details?.company?.address?.line2 ?? '',
        city: quotation?.details?.company?.address?.city ?? '',
        postalCode: quotation?.details?.company?.address?.postalCode ?? undefined,
      },
    },
  })

  const onSubmit: SubmitHandler<FormInputs> = (newData) => {
    track({
      event: 'submitted_company_details',
      quotation_id: id,
    })

    proceed(() =>
      updateQuotation({
        details: {
          company: {
            ...replaceNullsWithUndefineds(newData),
            address: {
              ...replaceNullsWithUndefineds(newData.address),
              country: CountryEnum.France,
            },
          },
        },
      })
    )
  }

  watch('siret')

  const siretQuery = useDebounce(getValues('siret'), 500)
  const isDebounced = getValues('siret') !== siretQuery

  useEffect(() => {
    track({
      event: 'visited_company_details',
      quotation_id: id,
    })
  }, [id])

  return (
    <>
      <OnboardingTitle.H1>Voici les informations concernant votre entreprise</OnboardingTitle.H1>

      <form className="w-full space-y-8" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <OnboardingTitle.H2>Votre entreprise</OnboardingTitle.H2>

          <div className="space-y-3">
            <div>
              <Input
                type="text"
                label="Nom ou raison sociale"
                autoComplete="organization"
                {...register('name')}
              />
              <FormError error={errors.name} />
            </div>

            <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
              <div className="w-full sm:w-[25%]">
                <Input label="Code NAF" {...register('nafCode')} />
                <FormError error={errors.nafCode} />
              </div>

              <div className="w-full sm:w-[75%]">
                <Input label="Numéro de SIRET" {...register('siret')} />
                <FormError error={errors.siret} />
              </div>
            </div>

            <div>
              <Input label="Statut juridique" {...register('legalStatus')} />
              <FormError error={errors.legalStatus} />
            </div>

            <div>
              <Input label="Date de création" type="date" {...register('foundedAt')} />
              <FormError error={errors.foundedAt} />
            </div>
          </div>
        </div>

        <div>
          <OnboardingTitle.H2>Votre siège social</OnboardingTitle.H2>

          <div className="space-y-3">
            <div>
              <Input label="Adresse" {...register('address.line1')} />
              <FormError error={errors.address?.line1} />
            </div>

            <div>
              <Input label="Complément" {...register('address.line2')} />
              <FormError error={errors.address?.line2} />
            </div>

            <div className="flex flex-col items-center gap-3 sm:flex-row">
              <div className="w-full sm:w-[25%]">
                <Input
                  label="Code postal"
                  placeholder="Bâtiment, numéro d'appartement"
                  {...register('address.postalCode')}
                />
                <FormError error={errors.address?.postalCode} />
              </div>

              <div className="w-full sm:w-[75%]">
                <Input label="Ville" {...register('address.city')} />
                <FormError error={errors.address?.city} />
              </div>
            </div>
          </div>
        </div>

        <OnboardingButtons>
          <OnboardingNextButton disabled={isDebounced} className="order-1 sm:order-2" />

          <Button variant="outlined" className="order-2 sm:order-1" id="no-siret-button">
            Je n'ai pas de siret
          </Button>
          <Tooltip
            content="Veuillez nous faire part de votre demande par email à notre service commercial à gestion@olino.fr"
            anchorSelect="#no-siret-button"
          />
        </OnboardingButtons>
      </form>
    </>
  )
}

const Tips = () => {
  return (
    <TipsCard
      title="Pas de panique !"
      content={[
        "Les informations ont automatiquement été complétées. Vous pouvez les modifier en cas d'erreur.",
      ]}
    />
  )
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage
