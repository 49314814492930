import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import 'react-tooltip/dist/react-tooltip.css'
import './index.css'

import App from './App'

Sentry.init({
  dsn: 'https://b0a99f70a5e44569882bc87dc586fe99@o561799.ingest.sentry.io/4504243764002816',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      blockAllMedia: true,
      maskAllInputs: true,
    }),
  ],
  enabled: import.meta.env.VITE_ENV !== 'development',
  environment: import.meta.env.VITE_ENV,

  tracesSampleRate: 0.25,
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0,
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
