import { useNavigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { twMerge } from 'tailwind-merge'

import PartnerLogo from '../components/PartnerLogo'

import { useOnboardingWorkflow } from './workflow'

import { cn } from '@olino/design-system'

// THIS IS A PLACEHOLDER FOR NOW
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NavBar = () => (
  <nav className="sticky top-0  hidden h-screen w-full max-w-[150px] flex-shrink-0 flex-row items-stretch py-6 md:flex">
    <ul className="flex flex-1 flex-col justify-center pl-5">
      <li>Grand 1</li>
      <li>Grand 2</li>
      <li>Grand 3</li>
      <li>Grand 4</li>
      <li>Grand 5</li>
    </ul>
    <div className="bg-gray-100 mx-2 my-auto h-4/5 w-0.5" />
  </nav>
)

const BackButton = () => {
  const navigate = useNavigate()

  return (
    <button
      className="mb-2 inline-flex items-center text-primary-900/70"
      type="button"
      onClick={() => {
        navigate(-1)
      }}
    >
      <FontAwesomeIcon icon={faArrowLeft} className="mr-1 text-xs" /> Précédent
    </button>
  )
}

const Header = () => {
  return (
    <header className={cn('fixed inset-x-0 top-0 z-10 h-16 bg-primary-900 lg:hidden')}>
      <div className="container mx-auto flex h-full w-full items-center gap-2 px-4">
        {/* <button type="button" className={cn('flex w-5 flex-none items-center justify-center')}>
          <FontAwesomeIcon icon={faBars} className="text-xl text-white sm:text-base" />
        </button> */}

        <div className="flex-1">
          <PartnerLogo />
        </div>

        {/* Spacer to have logo in the center when the burger will be implemented */}
        {/* <div className="w-5 flex-none" /> */}
      </div>
    </header>
  )
}

const Layout = ({ main, tips }: { main: React.ReactNode; tips: React.ReactNode }) => {
  const { currentStep } = useOnboardingWorkflow()

  return (
    <div className="flex flex-row">
      {/* <OnboardingSidebar /> */}

      {/* Left padding for center content */}
      <div className="hidden min-w-[64px] flex-1 bg-white lg:block" />

      {/* Center content */}
      <div className="flex min-h-screen max-w-7xl flex-grow-[5000] flex-row">
        {/* Main content */}
        <div className="flex flex-grow flex-col bg-white">
          <Header />

          <div className="flex flex-grow flex-row">
            {/* <NavBar /> */}

            <main
              className={twMerge(
                'relative flex w-full flex-grow flex-col p-4 py-6 lg:justify-center lg:pl-0 lg:pr-16'
              )}
            >
              <div className="header-spacer h-16 lg:hidden" />
              {!currentStep?.[1]?.hideBackwardButton && <BackButton />}
              {main}
              <div className="mt-8 block lg:hidden">{tips}</div>
            </main>
          </div>
        </div>

        {/* Right green banner */}
        <aside className="sticky top-0 hidden h-screen w-[400px] flex-shrink-0 flex-col items-center justify-center bg-primary-900 py-6 pl-16 text-white lg:flex">
          <PartnerLogo className="mb-12 w-full" />

          <div className="flex-1">{tips}</div>

          <p className="mt-3 text-xs">
            Groupe Rouge (Olino) est immatriculé auprès de l'ORIAS sous le numéro 07009049, régie
            par le Code des Assurances.
          </p>
        </aside>
      </div>
      {/* Right padding for center content */}
      <div className="-ml-px hidden min-w-[64px] flex-1 bg-primary-900 lg:block" />
    </div>
  )
}

export { Layout as OnboardingLayout }
