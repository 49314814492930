import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router'

import { useQuotation } from '../context'
import { type OnboardingPage } from '../routes'
import Container from '../../components/quotation/container/Container'
import {
  useCompleteQuotationMutation,
  PaymentFrequency,
  useRegisterPaymentMethodMutation,
} from '../../generated/graphql'
import { track } from '../../utils/analytics'
import formatPrice from '../../utils/formatPrice'
import stripePromise from '../../utils/stripe'
import useMe from '../../utils/useMe'
import { CheckoutAgreement } from '../components/CheckoutAgreement'
import { CheckoutRecapCard } from '../components/CheckoutRecapCard'
import { useOnboardingWorkflow } from '../workflow'
import { OnboardingNextButton } from '../components/OnboardingNextButton'
import { OnboardingTitle } from '../components/OnboardingTitle'
import { CheckoutPaymentCards } from '../components/CheckoutPaymentCard'

import { Card, CardContent, Loader } from '@olino/design-system'

const CheckoutPage: React.FC = () => {
  const stripe = useStripe()
  const elements = useElements()
  const user = useMe()

  const { quotation, isUpdating } = useQuotation()
  const { proceed } = useOnboardingWorkflow()
  const [paymentInformationsValid, setPaymentInformationsValid] = useState(false)
  const [agreement, setAgreement] = useState(false)
  const [isProceeding, setIsProceeding] = useState(false)

  const { mutateAsync: transformQuotation } = useCompleteQuotationMutation({
    onSuccess: () => {
      track({
        event: 'submitted_checkout',
        quotation_id: quotation.id,
      })

      proceed()
    },
  })

  const discountedTotal = quotation.premium?.discountedTotal

  const total = quotation.premium?.originalTotal || 0

  const totalApplicationFees = quotation.premium?.applicationFeesTotal

  const annualTotalAfterPotentialDiscount = discountedTotal || total || 0

  const monthlyTotalAfterPotentialDiscount = annualTotalAfterPotentialDiscount / 12

  const onClick = async () => {
    setIsProceeding(true)
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }

      if (!user) throw Error('No user while trying to setup payment')

      const { error } = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      })

      if (error) {
        toast.error(error.message)
        Sentry.captureException(error)
        console.error(error)
        return
      }

      await transformQuotation({
        quotationId: quotation.id,
      })
    } finally {
      setIsProceeding(false)
    }
  }

  return (
    <Container>
      <OnboardingTitle.H1>Le paiement de votre formule</OnboardingTitle.H1>

      <Card className="mb-4 max-w-lg">
        <CardContent>
          <div className="flex flex-row items-start justify-between">
            <div className="font-bold text-primary-900">
              Paiement{' '}
              {quotation.details.paymentFrequency === PaymentFrequency.Monthly
                ? 'mensuel'
                : 'annuel'}{' '}
            </div>
            <div className="relative text-right">
              <Loader
                className={twMerge('absolute inset-0', isUpdating ? 'visible' : 'invisible')}
              />
              <div
                className={twMerge(
                  'font-serif text-2xl font-black text-primary-900',
                  isUpdating ? 'invisible' : 'visible'
                )}
              >
                {formatPrice(
                  quotation.details.paymentFrequency === PaymentFrequency.Monthly
                    ? monthlyTotalAfterPotentialDiscount
                    : annualTotalAfterPotentialDiscount
                )}
              </div>
              <div
                className={twMerge(
                  'text-sm font-light leading-tight text-grey-400',
                  isUpdating ? 'invisible' : 'visible'
                )}
              >
                {quotation.details.paymentFrequency === PaymentFrequency.Monthly
                  ? 'par mois'
                  : 'par an'}
              </div>
            </div>
          </div>

          <div className="relative mt-2 text-sm">
            <Loader
              size="small"
              className={twMerge('absolute inset-0', isUpdating ? 'visible' : 'invisible')}
            />
            <div className={twMerge(isUpdating ? 'invisible' : 'visible')}>
              {quotation.details.paymentFrequency === PaymentFrequency.Monthly ? (
                <>
                  Des frais de dossier s'appliqueront à la première échéance{' '}
                  <span className="font-bold">
                    d' un montant de {formatPrice(totalApplicationFees || 0)}
                  </span>
                </>
              ) : (
                `Paiement en une fois, vous économisez les frais de dossier chaque année.`
              )}
            </div>
          </div>
        </CardContent>
      </Card>

      <CheckoutRecapCard />

      <CheckoutPaymentCards onChange={(e) => setPaymentInformationsValid(e.complete)} />

      <CheckoutAgreement
        className="mb-3 flex max-w-lg flex-row items-center gap-2 text-justify text-xs leading-none"
        value={agreement}
        onChange={setAgreement}
      />

      <OnboardingNextButton
        loading={isProceeding}
        disabled={!paymentInformationsValid || !agreement}
        onClick={onClick}
      >
        Souscrire
        <FontAwesomeIcon icon={faArrowRight} />
      </OnboardingNextButton>
    </Container>
  )
}

const Main = () => {
  const { id } = useParams()
  const { mutateAsync: createSetupIntent } = useRegisterPaymentMethodMutation()
  const { quotation } = useQuotation()

  // Using useQuery to trigger the mutation onMount and dedup the call
  const { data } = useQuery(['query--createSetupIntent'], () => {
    return createSetupIntent({ quotationId: quotation.id })
  })

  useEffect(() => {
    track({
      event: 'visited_checkout',
      quotation_id: id,
    })
  }, [id])

  if (!data?.clientSecret) return null

  return (
    <Elements
      stripe={stripePromise}
      options={{
        appearance: {
          theme: 'flat',
          variables: {
            colorPrimary: '#1c4433',
            fontFamily: 'Poppins, system-ui, sans-serif',
            colorBackground: '#F9F9F9',
          },
        },
        clientSecret: data.clientSecret,
        locale: 'fr',
      }}
    >
      <CheckoutPage />
    </Elements>
  )
}

const Tips = () => {
  return undefined
}

export default {
  main: <Main />,
  tips: <Tips />,
} satisfies OnboardingPage
